import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/sY_Y4ojSYP4"
    bibleGroupSrc="https://www.docdroid.net/Z4InZ2A/bible-group-homework-6-21-pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="When Church Don't Listen - Colossians" />
  </Layout>
)

export default SermonPost
